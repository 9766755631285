<template>
  <div class="card" style="min-height: calc(100vh - 180px)">
    <div class="bg-blue-light p-1">
      <TitleButton
          btnTitle="Add New"
          :showBtn="false"
          :showAddNew="true"
          title="Production list"
          @onClickAddNewButton="navigateToAddPage"
      />
    </div>

    <div class="px-2">
      <DateQuerySetter
          btnTitle="Go"
          @onClickGo="getProductionList"
      >
        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label class="col-form-label" for="product_id">Product/Item</label>
          <AsyncSelect
              placeholder="Select Product"
              v-model="product"
              :api-service="fetchProductList"
              :format-label="option => option.text"
              label="text"
          />
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label class="col-form-label" for="product_costing_master_id">Product Costing</label>
          <AsyncSelect
              placeholder="Select Product Costing"
              v-model="productCosting"
              :api-service="fetchProductCosting"
          />
        </div>

        <div class="col-12 col-sm-6 col-md-4 col-lg-3">

          <div class="d-flex align-items-center justify-content-between filter-label">
            <label for="colFormLabel" class="col-form-label">Bill Number/Batch number: </label>
          </div>

          <input
              placeholder="Bill Number/Batch number"
              v-model="searchTerm"
              class="form-control"
          />
        </div>
      </DateQuerySetter>

    </div>
    <div class="col-12 px-2 mt-1">
      <ListTable :productions="productions"/>
    </div>
    <div class="px-2">
      <Pagination :offset="offset" @onPageChange="onPageChange" ref="pagination"/>
    </div>
    <GlobalLoader/>
  </div>
</template>

<script setup>
import DateQuerySetter from '@/components/atom/DateQuerySetter'
import {ref, onMounted, computed} from 'vue'
import {useStore} from 'vuex'
import {useRouter, useRoute} from 'vue-router'
import handlePurchase from '@/services/modules/purchase'
import TitleButton from '@/components/atom/TitleButton'
import Pagination from '@/components/atom/Pagination'
import ListTable from '@/components/molecule/company/inventory/production/ListTable.vue'
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
import {trim} from "lodash";

const router = useRouter()
const store = useStore()
let offset = ref(20)
let productions = ref([])
const route = useRoute()
let product = ref(null)
let productCosting = ref(null)
let searchTerm = ref(null)

const {fetchProductionList, fetchProductList, fetchProductCosting} = handlePurchase()
const companyId = computed(() => route.params.companyId)
const startDate = computed(() => route.query.start)
const endDate = computed(() => route.query.end)
const page = computed(() => route.query.page)

const additionalContactOption = ref({
  roles: 'customer'
});

function navigateToAddPage() {
  router.push({name: 'production-add', params: route.params, query: route.query})
}

function getQuery() {
  let query = '?company_id=' + companyId.value
  if (productCosting.value) query += '&product_costing_id=' + productCosting.value.id
  if (product.value) query += '&product_id=' + product.value.id
  if (searchTerm.value && trim(searchTerm.value) !== '') query += '&search_term=' + trim(searchTerm.value)
  if (!startDate.value && !endDate.value) return query
  if (startDate.value) query += '&start_date=' + startDate.value
  if (endDate.value) query += '&end_date=' + endDate.value
  if (page.value) query += '&page=' + page.value
  query += '&offset=' + offset.value
  return query
}

function getProductionList() {
  fetchProductionList(getQuery()).then(({status, data}) => {
    if (!status) {
      productions.value = []
      return store.commit("resetPagination")
    }
    productions.value = data.data
    setPagination(data)
  })
}

function setPagination(data) {
  if (!data.data.length) return
  store.commit('setPaginationData', {
    records: data.total,
    perPage: data.per_page,
    currentPage: data.current_page,
  })
}

function onPageChange(page) {
  let routeQuery = Object.assign({}, route.query)
  routeQuery.page = page
  router.push({path: route.path, query: routeQuery})
  getProductionList()
}

onMounted(() => {
  getProductionList()
})

</script>
