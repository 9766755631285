<template>
  <div class="card" style="min-height: 200px">
    <div class="table-responsive">
      <table class="table">
        <thead>
        <tr>
          <th>Date</th>
          <th>Bill Number</th>
          <th>Batch No</th>
          <th>Production Costing Name</th>
          <th>Customer</th>
          <th>Production Quantity</th>
          <th class="text-right">FG Value</th>
          <th>Action</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, i) in productions" :key="i">
          <td>{{ item.date }}</td>
          <td>{{ item.bill_number }}</td>
          <td>{{ item.batch_no }}</td>
          <td>{{ item.product_costing_name }}</td>
          <td>{{ item.customer_name }}</td>
          <td>{{ item.production_quantity }}</td>
          <td class="text-right">{{ commaFormat(item.finished_goods_value) }}</td>
          <td class="d-flex">
            <button class="btn btn-primary btn-sm" @click="goToEdit(item.id)" style="cursor: pointer">
              <i class="fas fa-eye"></i>
            </button>
            <button class="btn btn-secondary btn-sm" @click="goToPrint(item.id)"
                    style="cursor: pointer; margin-left: 10px">
              <i class="fas fa-print"></i>
            </button>
          </td>
        </tr>
        </tbody>
      </table>
      <p v-if="!productions.length" class="text-center mt-5">No data</p>
    </div>
  </div>
</template>

<script setup>
import figureFormatter from '@/services/utils/figureFormatter';
import handlePurchase from '@/services/modules/purchase';
import pdfPrinter from '@/services/utils/pdf/production';
import handleCompany from "@/services/modules/company";
import handleBarcode from "@/services/modules/barcode";
import {useRoute, useRouter} from "vue-router";
import {computed, onMounted, ref} from "vue";
import {useStore} from "vuex";
import {formatDate} from '@/services/utils/global';

const {exportToPDF} = pdfPrinter();
const {fetchBarcode} = handleBarcode();
const {fetchSingleProduction} = handlePurchase()
const {fetchCompanyInfo} = handleCompany();
const {commaFormat} = figureFormatter()
const props = defineProps({
  productions: Array
})

const companyId = computed(() => route.params.companyId)
const route = useRoute()
const router = useRouter()
const store = useStore()

const companyInfo = ref({})
const singleProduction = ref({})
const barcodeQuery = ref('')
const barcode = ref('')
const qrcode = ref('')
const goToEdit = (id) => {
  let param = {
    companyId: route.params.companyId,
    moduleId: route.params.moduleId,
    menuId: route.params.menuId,
    pageId: route.params.pageId,
    productionId: id,
  }

  router.push({
    name: 'production-edit',
    params: param,
    query: route.query
  })
}
const goToPrint = (id) => {
  const user = store.state.user.user
  const companyQuery = `?company_id=${route.params.companyId}`;

  fetchSingleProduction(id, companyQuery).then(({status, data}) => {
    if (status) {
      singleProduction.value = data;
      let qrcodeData = JSON.stringify({
        ref_no: singleProduction.value.bill_number,
        ref_date: singleProduction.value.production_date,
        created_by: user.name,
        created_date_time: formatDate(new Date())
      })
      barcodeQuery.value = `?code=${singleProduction.value.bill_number}&qrcode=${qrcodeData}`;
    }
  }).then(() => {
    fetchBarcode(barcodeQuery.value).then((res) => {
      barcode.value = res.barcode;
      qrcode.value = res.qrcode
    }).then(() => {
      exportToPDF(companyInfo.value, singleProduction.value, barcode.value, qrcode.value, user.name)
    })
  }).catch((error) => {
    console.log(error);
  })
}

onMounted(() => {
  fetchCompanyInfo(companyId.value).then((res) => {
    companyInfo.value = res.data;
  }).catch((error) => {
    console.log(error);
  });
})
</script>
